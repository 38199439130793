<template>
	<ZyroModal
		max-width="350px"
		max-height="565px"
		:title="$t('common.linkSettings')"
		class="add-link-modal"
		@close-modal="closeModal"
	>
		<LinkSettings
			v-if="linkSettingsData.content"
			:base-target="linkSettingsData.target"
			:base-href="linkSettingsData.href"
			:base-content="linkSettingsData.content"
			@settings-update="linkSettingsData = {
				...linkSettingsData,
				...$event
			}"
		/>
		<ZyroFieldToggle
			id="addLinkModal-toggle"
			v-qa="`linksettingsmodal-hidepage-toggle-${isItemHidden ? 'hidden' : 'visible'}`"
			:checked="isItemHidden"
			:label="$t('common.hidePage')"
			@change="isItemHidden = !isItemHidden"
		/>
		<template slot="footer">
			<ZyroButton
				v-qa="'linksettingsmodal-btn-close'"
				@click="closeModal"
			>
				{{ $t('common.cancel') }}
			</ZyroButton>
			<ZyroButton
				v-qa="'linksettingsmodal-btn-submit'"
				theme="primary"
				@click="submitForm"
			>
				{{ $t('common.save') }}
			</ZyroButton>
		</template>
	</ZyroModal>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default {
	components: { LinkSettings },
	data() {
		return {
			linkSettingsData: {
				href: '',
				isUrlValid: true,
				target: '',
				content: '',
			},
			isItemHidden: false,
		};
	},
	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapState(['website']),
		...mapGetters('navigation', ['getIsItemHidden']),
	},
	mounted() {
		const { itemId } = this.activeModalSettings;
		const page = this.website.navigation.items[itemId];

		this.linkSettingsData.content = this.activeModalSettings.pageName;
		this.linkSettingsData.href = page.url;
		this.linkSettingsData.target = page.target;
		this.isItemHidden = this.getIsItemHidden(itemId);
	},
	methods: {
		...mapActions('navigation', [
			'setItemData',
			'hideItem',
			'showItem',
		]),
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		submitForm() {
			if (!this.linkSettingsData.isUrlValid) {
				return;
			}

			const { itemId } = this.activeModalSettings;

			this.setItemData({
				itemId,
				data: {
					name: this.linkSettingsData.content || 'New link',
					url: this.linkSettingsData.href,
					target: this.linkSettingsData.target,
				},
			});

			if (this.isItemHidden) {
				this.hideItem({ itemId });
			} else {
				this.showItem({ itemId });
			}

			this.closeModal();
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal .modal__content {
	overflow: visible;
}
</style>
